import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "key": "reusable",
  "langKey": "en",
  "title": "unit32-gallery-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <body>
    <div className="gallery">
        <img src="/files/unit32/20190913_105213.jpg"></img>
        <img src="/files/unit32/20190913_105344.jpg"></img>
        <img src="/files/unit32/20190913_105428.jpg"></img>
        <img src="/files/unit32/20190913_105540.jpg"></img>
        <img src="/files/unit32/20190913_105415.jpg"></img>
    </div>
    </body>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      